<template>
  <div>
    <div style="display: flex">
      <el-container>
        <el-main >
          <head-layout

            :head-btn-options="headBtnOptions"
            head-title="1#原料磨分析"
          >
          </head-layout>
          <div style="margin: 20px; display: flex; width: 300px">
            <el-date-picker
              v-model="startParms.endTime"
              value-format="yyyy-MM-dd"
              :picker-options="setDateRange"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <el-button
              type="primary"
              @click="timeSearch"
              style="margin-left: 10px"
              >查询</el-button
            >
          </div>
          <!-- 星星box -->
          <div class="starBoxclass" ref="myDiv">
            <el-card
              style="min-width: 200px; text-align: center;margin:20px"
              v-for="(item, index) in starBox"
              :key="index"
              :class="{'chooesd':item.xmbh == currentStar.xmbh}"
              @click.native="FiveToES(item)"
            >
              <span style="font-size: 14px">{{ item.xmmc }}</span>
              <br />
              <span style="font-size: 14px">{{ item.ds }}</span>
            </el-card>
          </div>

          <div style="display: flex; margin-top: 20px">
            <div id="fiveE" class="echart" :style="myChartStyle"></div>
            <div id="fiveEz" class="echart" :style="myChartStyle"></div>
          </div>

          <el-table
            :data="tableData2"
            border
            style="margin-top: 20px"
            height="150"
          >
            <el-table-column prop="anomalyItem" label="分析项">
            </el-table-column>
            <el-table-column prop="standardVal" label="标准值">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.standardVal"
                  v-if="scope.row.edit"
                ></el-input>
                <span v-if="!scope.row.edit">{{ scope.row.standardVal }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="itemVal" label="实际值"> </el-table-column>
            <el-table-column prop="describe" label="异常描述">
            </el-table-column>
            <el-table-column prop="startTime" label="开始时间">
            </el-table-column>
            <el-table-column prop="endTime" label="截止时间"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="editRow(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" @click="saveRow(scope.row)"
                  >保存</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div style="display: flex; margin-top: 30px">
            <el-tree
              check-strictly
              :data="treeList2"
              show-checkbox
              node-key="xmmc"
              ref="treeL2"
              highlight-current
              :props="defaultProps"
              default-expand-all
              @check-change="
                (data, checked, indeterminate) =>
                  TreeCheck(data, checked, indeterminate)
              "
            >
            </el-tree>
            <div style="width: 100%">
              <!-- <div v-for="(item, index) in chartList" :ref="item.id" :key="item.id" class="echart" :id=item.id
                                :style="myChartStyle">
                            </div> -->
              <div
                ref="BottomEchart"
                class="echart"
                :style="myChartStyle"
                id="BottomEchart"
              ></div>
            </div>
          </div>
        </el-main>
      </el-container>

      <el-drawer
        :title="tableTitle"
        :append-to-body="true"
        :visible.sync="dialog"
      >
        <el-table :data="tableData">
          <el-table-column property="sj" label="时间"></el-table-column>
          <el-table-column property="ds" label="值"></el-table-column>
        </el-table>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index";
import {
  dataApisTree,
  xmsjHour,
  ZBtreeLIst,
  ZBList,
  starList,
  listxmsjDay,
  xmsjHour2,
  updateNhfxSummary,
} from "@/api/dataAcquisition/index";
import * as echarts from "echarts";

export default {
  components: {
    CommonTree,
    HeadLayout,
    GridLayout,
  },
  watch: {},
  data() {
    return {
      currentStar: "",
      addtime: 1,
      treeList2: [],
      activeNames: ["1"],
      treekey: "xmmc",
      tableTitle: "",
      searchTitle: "xmmc",
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },

      tableData: [],
      dialog: false,
      chartList: [],
      tableLoading: false,
      filterText: "",
      activeName1: "1",
      activeName: "list",
      defaultProps: {
        children: "childrenList",
        label: "xmmc",
      },
      treedata: [],
      excellent: "",
      myChartStyle: {
        float: "left",
        width: "100%",
        height: "500px",
        margin: "5px",
      }, // 图表样式
      myChartStyle2: {
        float: "left",
        width: "48%",
        height: "450px",
        margin: "5px",
      }, // 图表样式
      timeData: [],

      startParms: {
        appId: "",
        appNo: "",
        endTime: "2023-05-07",
      },
      starBox: [],
      ESid: "",
      tableData2: [],
      List: [],
      lineList: [],

      xAxis: {
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        data: [],
      },
      option: {
        tooltip: {},
        title: {
          show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
          // text: this.List[0].mc,//主标题文本，'\n'指定换行
        },
        xAxis: {},
        yAxis: {},
        legend: {
          data: [],
          padding: [0, 0, 20, 0],
          top: "", // 设置图例位置在顶部
        },
        series: [
          // {
          //     type: "line", // 形状为折现
          //     data: this.lineList,
          //     name: "小时", // legend属性
          //     symbolSize: 10,
          //     label: {
          //         // 柱状图上方文本标签，默认展示数值信息
          //         // show: true,
          //         // position: "top",
          //     },
          // },
        ],
      },
      option2: {},
    };
  },

  methods: {
    //班组tree
    MdataApisTree() {
      dataApisTree().then((res) => {
        this.treedata = res.data.data;
        this.treedata.forEach((element) => {
          element.disabled = true;
        });
      });
    },

    TreeCheck(data, checked, indeterminate) {
      if (checked) {
        let parms = [
          {
            xmbh: data.xmbh,
            beginTime: this.startParms.endTime, //时间
          },
        ];

        xmsjHour2(parms).then((res) => {
          this.chartList.push({ id: data.xmbh, name: data.xmmc });
          let List = res.data.data[Object.keys(res.data.data)[0]];
          let lineList = [];
          let Xdata = [];
          let that = this;
          List.forEach((element) => {
            lineList.push({ value: element.ds, zsvalue: element.actDs });
            Xdata.push(element.sj.slice(10, element.sj.length - 6));
          });

          this.xAxis.data = Xdata;
          this.option.xAxis = this.xAxis;
          this.option.legend.data.push(data.xmmc);
          this.option.series.push({
            type: "line", // 形状为折现
            data: lineList,
            name: data.xmmc, // legend属性
            symbolSize: 8,
            label: {
              // 柱状图上方文本标签，默认展示数值信息
              // show: true,
              // position: "top",
            },
          });

          this.option.tooltip = {
            formatter(params) {
              // return '真实值: ' + params.data.zsvalue + '</br>' + '百分比: ' + params.data.value
              return that.showalltooltip(params.dataIndex, params.name);
            },
          };

          this.$nextTick(() => {
            let myChart = echarts.init(document.getElementById("BottomEchart"));
            myChart.dispose();
            this.initEcharts("BottomEchart", this.option);
          });
        });
      }
      if (!checked) {
        let yname = "";
        this.chartList.forEach((element, index) => {
          if (element.id == data.xmbh) {
            yname = element.name;
            this.chartList.splice(index, 1);
          }
        });
        this.option.series.forEach((element, index) => {
          if (element.name == yname) {
            this.option.series.splice(index, 1);
          }
        });
        this.option.legend.data.forEach((element, index) => {
          if (element == yname) {
            this.option.legend.data.splice(index, 1);
          }
        });
        let that = this;
        this.option.tooltip = {
          formatter(params) {
            return that.showalltooltip(params.dataIndex, params.name);
          },
        };
        let myChart = echarts.init(document.getElementById("BottomEchart"));
        myChart.dispose();
        this.initEcharts("BottomEchart", this.option);
      }
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    showalltooltip(idx, name) {
      let aalist = name + "时" + "</br>";
      this.option.series.forEach((element) => {
        aalist += element.name + ":" + element.data[idx].zsvalue + "</br>";
      });
      return aalist;
    },
    showalltooltip2(idx, name) {
      let aalist = name + "</br>";

      for (let key in this.option2) {
        //合格基线
        if (idx == 1 && key == "red") {
          this.option2.red.forEach((element) => {
            aalist += element.xmmc + ":" + element.actValue + "</br>";
          });
        }
        if (idx == 2 && key == "green") {
          this.option2.green.forEach((element) => {
            aalist += element.xmmc + ":" + element.actValue + "</br>";
          });
        }
        if (idx == 0 && key == "ds") {
          this.option2.ds.forEach((element) => {
            aalist += element.xmmc + ":" + element.actValue + "</br>";
          });
        }
      }

      return aalist;
    },

    //查询时间更新echarts
    timeSearch() {
      this.MstarList(this.startParms);
    },

    initEcharts(id, option) {
      const myChart = echarts.init(document.getElementById(id));
      myChart.setOption(option);
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },

    delChart() {
      this.chartList.forEach((element, index) => {
        console.log(element.id, document.getElementById(element.id));
        let myChart = echarts.init(document.getElementById(element.id));
        myChart.dispose();
        this.chartList.splice(index, 1);
      });
    },

    MstarList(e) {
      starList(e).then((res) => {
        this.starBox = res.data.data.starList;
        let zb = res.data.data.starDimension;
        let greenList = JSON.parse(
          JSON.stringify(res.data.data.starDimension.green)
        );
        let MaxList = res.data.data.starDimension.green;
        let redList = res.data.data.starDimension.red;
        this.tableData2 = res.data.data.nhfxList;
        this.tableData2.forEach((element) => {
          this.$set(element, "edit", false);
        });

        this.option2 = res.data.data.starDimension;
        this.starBox.forEach((element) => {
          MaxList.forEach((element2) => {
            if (element.xmbh == element2.xmbh) {
              element.green = element2.value;
            }
          });
        });
        this.starBox.forEach((element) => {
          zb.ds.forEach((element2) => {
            if (element.xmbh == element2.xmbh) {
              element2.zsvalue = element.ds;
            }
          });
        });
        // this.currentStar = this.starBox[0]
        let that = this;
        let option = {
          tooltip: {
            // valueFormatter: function (value) {
            //     return (value * 100).toFixed(2);
            // }

            formatter(params) {
              return that.showalltooltip2(params.dataIndex, params.name);
            },
          },
          legend: {
            show: true,
            color: ["red", "orange", "red"],
            data: [
              { name: "数据值", icon: "circle" },
              { name: "合格基线", icon: "circle" },
              { name: "优秀基线", icon: "circle" },
            ],
            itemWidth: 6,
            orient: "vertical",
            left: "left",
            left: "2%",
            //  right: "right",
            // right: "2%",
            height: 30,
            top: "0%",
            textStyle: {
              color: "#333",
              fontSize: 14,
            },
          },
          radar: {
            name: {
              textStyle: {
                color: "#333",
                fontSize: 14,
                borderRadius: 3,
                textAlign: "center",
              },
            },
            radius: "70%",//缩放
            center: ["50%", "50%"],
            indicator: [],

            splitArea: {
              areaStyle: {
                color: [
                  "rgba(255,255,255, 0.5)",
                  "rgba(255,255,255, 0.3)",
                  "rgba(255,255,255, 0.2)",
                  "rgba(255,255,255, 0.2)",
                  "rgba(255,255,255, 0.1)",
                ],
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 10,
              },
            },
          },
          series: [
            {
              name: "",
              type: "radar",
              data: [
                {
                  value: [],
                  name: "数据值",
                  lineStyle: {
                    color: "blue",
                  },
                  itemStyle: {
                    color: "blue",
                  },
                },
                {
                  value: [],
                  name: "合格基线",
                  lineStyle: {
                    color: "orange",
                  },
                  itemStyle: {
                    color: "orange",
                  },
                },
                {
                  value: [],
                  name: "优秀基线",
                  lineStyle: {
                    color: "red",
                  },
                  itemStyle: {
                    color: "red",
                  },
                },
              ],
            },
          ],
        };

        zb.ds.forEach((element) => {
          let result = "";
          for (let i = 0; i < element.xmmc.length; i += 10) {
            result += element.xmmc.substr(i, 10) + "\n";
          }
          option.radar.indicator.push({ name: result, max: 1 });
        });

        option.series[0].data.forEach((element) => {
          if (element.name == "合格基线") {
            redList.forEach((element2) => {
              element.value.push(element2.value);
            });
          }
          if (element.name == "优秀基线") {
            greenList.forEach((element2) => {
              element.value.push(element2.value);
            });
          }
          if (element.name == "数据值") {
            zb.ds.forEach((element2) => {
              // element.value.push({ value: element2.value, zsvalue: element2.zsvalue })
              element.value.push(element2.value);
            });
          }
        });

        // this.starBox
        this.initEcharts("fiveE", option);

        this.FiveToES({
          //   xmbh: this.starBox[0].xmbh,
          //   xmmc: this.starBox[0].xmmc,
          xmbh: this.currentStar.xmbh,
          xmmc: this.currentStar.xmmc,
        });

        if (this.addtime == 1) {
          this.treeList2 = res.data.data.treeList.xmmcList;
          this.treeList2.forEach((element) => {
            element.disabled = true;
          });
          this.TreeCheck(this.treeList2[0].childrenList[0], true);
          this.$refs.treeL2.setCheckedKeys([
            this.treeList2[0].childrenList[0].xmmc,
          ]);
          this.addtime++;
        } else {
          let parms = [];

          if (this.chartList.length > 0) {
            this.chartList.forEach((element) => {
              parms.push({
                xmbh: element.id,
                beginTime: this.startParms.endTime,
              });
              // let myChart = echarts.init(document.getElementById(element.id));
              // myChart.dispose();
            });
            xmsjHour2(parms).then((res) => {
              let obj = res.data.data;
              this.option.series = [];
              for (let key in obj) {
                this.chartList.forEach((element) => {
                  if (element.id == key) {
                    let List = obj[key];
                    let Xdata = [];
                    let lineList = [];
                    List.forEach((element) => {
                      // lineList.push(element.ds)
                      lineList.push({
                        value: element.ds,
                        zsvalue: element.actDs,
                      });
                      Xdata.push(element.sj.slice(10, element.sj.length - 6));
                    });

                    this.xAxis.data = Xdata;
                    this.option.xAxis = this.xAxis;
                    this.option.legend.data.push(element.name);
                    this.option.series.push({
                      type: "line", // 形状为折现
                      data: lineList,
                      name: element.name, // legend属性
                      symbolSize: 8,
                      label: {
                        // 柱状图上方文本标签，默认展示数值信息
                        // show: true,
                        // position: "top",
                      },
                    });
                    let that = this;
                    this.option.tooltip = {
                      formatter(params) {
                        return that.showalltooltip(
                          params.dataIndex,
                          params.name
                        );
                      },
                    };

                    this.$nextTick(() => {
                      // this.initEcharts(key, option);
                      let myChart = echarts.init(
                        document.getElementById("BottomEchart")
                      );
                      myChart.dispose();
                      this.initEcharts("BottomEchart", this.option);
                    });
                  }
                });
              }
            });
          }
        }
      });
    },
    //创建折线图
    FiveToES(e) {
      this.currentStar = e;
      let myChart = echarts.init(document.getElementById("fiveEz"));
      myChart.dispose();
      this.ESid = e.xmbh;
      let parms = {
        // appId: this.startParms.appId,//应用id
        appNo: this.startParms.appNo,
        endTime: this.startParms.endTime, //时间
        indexList: [{ xmbh: e.xmbh }],
      };
      if (!e.xmbh) {
        parms.indexList[0].xmbh = this.starBox[0].xmbh;
        this.currentStar = this.starBox[0]
      }
      listxmsjDay(parms).then((res) => {
        let List = res.data.data[Object.keys(res.data.data)[0]];
        let lineList = [];

        let xAxis = {
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
          data: [],
        };
        // sj

        List.forEach((element) => {
          lineList.push(element.ds);
          xAxis.data.push(element.sj);
        });

        let greenLine = List[0].green;
        let redLine = List[0].red;
        let option = {
          title: {
            show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
            // text: List[0].mc,//主标题文本，'\n'指定换行
            text: e.xmmc,
          },
          xAxis: xAxis,
          yAxis: {},
          color: ["blue", "orange", "red"],
          legend: {
            // data: ["天", "及格基线", "优秀基线"],
            data: [
              {
                name: "天",
                textStyle: {
                  color: "blue", // 图例文字颜色
                },
              },

              {
                name: "及格基线",

                textStyle: {
                  color: "orange", // 图例文字颜色
                },
              },
              {
                name: "优秀基线",

                textStyle: {
                  color: "red", // 图例文字颜色
                },
              },
            ],

            // top: "0%", // 设置图例位置在顶部
            height: 30,
            top: "0%",
            right: "4%",
            bottom: "3%",
          },
          series: [
            {
              type: "line", // 形状为折现
              data: lineList,
              name: "天", // legend属性
              symbolSize: 8,
              label: {
                // 柱状图上方文本标签，默认展示数值信息
                show: true,
                position: "top",
              },
            },
            {
              type: "line", // 折线
              name: "及格基线", // legend属性
              symbolSize: 8,
              label: {
                // 柱状图上方文本标签，默认展示数值信息
                show: true,
                position: "top",
              },
              markLine: {
                silent: true,
                lineStyle: {
                  normal: {
                    // color: "red", // 这儿设置安全基线颜色
                  },
                },
                data: [
                  {
                    yAxis: redLine.toString(),
                  },
                ],
                label: {
                  normal: {
                    formatter: "及格基线", // 这儿设置安全基线
                  },
                },
              },
            },

            {
              type: "line", // 折线
              name: "优秀基线", // legend属性
              symbolSize: 8,
              label: {
                // 柱状图上方文本标签，默认展示数值信息
                show: true,
                position: "top",
              },
              markLine: {
                silent: true,
                lineStyle: {
                  normal: {
                    // color: "green", // 这儿设置安全基线颜色
                  },
                },
                data: [
                  {
                    yAxis: greenLine.toString(),
                  },
                ],
                label: {
                  normal: {
                    formatter: "优秀基线", // 这儿设置安全基线
                  },
                },
              },
            },
          ],
        };

        if (!e.xmmc) {
          option.title.text = this.starBox[0].xmmc;
        }

        let that = this;
        this.$nextTick(() => {
          this.initEcharts("fiveEz", option);

          let myChart = echarts.init(document.getElementById("fiveEz"));
          myChart.on("click", function (params) {
            if (params.componentSubType == "line") {
              that.startParms.endTime = params.name;
              that.MstarList(that.startParms);
            }
          });
        });
      });
    },

    // 默认时间
    timeDefault() {
      var date = new Date();
      var s1 =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return s1;
    },

    editRow(row) {
      row.edit = true;
      this.tableData2.forEach((element) => {
        if (element.id == row.id) {
          element.edit = true;
        }
      });
    },
    saveRow(row) {
      let params = {
        id: row.id,
        standardVal: row.standardVal,
      };

      updateNhfxSummary(params).then((res) => {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        row.edit = false;
      });
    },
    getDivWidth() {
const myDiv = this.$refs.myDiv
const width = myDiv.clientWidth
console.log('宽度为', width)
}
  },

  mounted() {
    this.startParms.endTime = this.timeDefault();
    this.startParms.appNo = "YLM1";

    this.MstarList(this.startParms);
    this.getDivWidth()
  },
  beforeDestroy() {
    // this.delChart()
  },
};
</script>

<style scoped lang="scss">
.starBoxclass{
  width: 100%;
  display: flex;
  overflow: scroll;
}
.chooesd{
  border: 1px red solid !important;
}

</style>
